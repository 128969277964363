import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import LogoutIcon from "@mui/icons-material/Logout";
import { Button, ListItemButton, Stack } from "@mui/material";

import useAuth from "../../hooks/useAuth";
import useCompany from "../../hooks/useCompany";
import { USER_TYPE } from "../../constants/value";
import { allItemTop, customerItems } from "../../constants/navbarList";

const TopNavBar = ({ handleDrawerToggle }) => {
  const { user } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openIndex, setOpenIndex] = useState(null);
  const nav = useNavigate();
  const { companyId } = useCompany();

  const [items, setItems] = useState([]);

  useEffect(() => {
    if (user.user_type === USER_TYPE.staff && user.company_id === 0) {
      const totalItems = [
        {
          text: companyId ? `Companies[${companyId}]` : "Companies",
          to: "/company",
        },
        ...allItemTop,
      ];

      setItems(companyId === 0 ? [totalItems[0]] : totalItems);
    } else {
      setItems(user.user_type === USER_TYPE.staff ? allItemTop : customerItems);
    }
  }, [user, companyId]);

  const [listItemTop, setListItemTop] = useState([]);

  useEffect(() => {
    setListItemTop([
      ...items,
      {
        text: "Logout",
        to: "/login",
        icon: <LogoutIcon />,
        hasNoLink: true,
        onclick: () => { 
          localStorage.clear();
          nav("/login");
        },
      },
    ]);
  }, [items]);

  const handleClick = (e, index) => {
    setOpenIndex(index);
    setAnchorEl(e.currentTarget);
  };
  const handleClose = (index) => {
    setOpenIndex(null);
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ mr: 2, display: { sm: "none" } }}
      >
        <MenuIcon />
      </IconButton>
      <Stack
        direction="row"
        spacing={2}
        sx={{ mr: 2, display: { xs: "none", sm: "flex" } }}
      >
        {listItemTop.map((list, index) =>
          list.subMenu && list.subMenu.length ? (
            <div key={`Button${list.text}`}>
              <Button
                color="inherit"
                key={`Button${list.text}`}
                id={`basic-button-${index}`}
                aria-controls={index === openIndex ? "basic-menu" : undefined}
                aria-haspopup="true" 
                aria-expanded={index === openIndex ? "true" : undefined}
                onClick={(e) => handleClick(e, index)}
              >
                {list.text}
              </Button>
              <Menu
                id={`basic-menu-${index}`}
                key={`Menu${list.text}`}
                open={index === openIndex}
                onClose={() => handleClose(index)}
                anchorEl={anchorEl}
              >
                {list.subMenu.map((eachSubMenu) => (
                  <ListItemButton
                    button
                    component={Link}
                    to={`${list.to}/${eachSubMenu.to}`}
                    key={`${list.to}/${eachSubMenu.to}`}
                  >
                    {eachSubMenu.text}
                  </ListItemButton>
                ))}
              </Menu>
            </div>
          ) : (
            <Button
              color="inherit"
              key={list.text}
              to={list.to}
              onClick={list?.onclick ? list?.onclick : () => nav(list.to)}
            >
              {list.text}
            </Button>
          )
        )}
      </Stack>
    </>
  );
};

export default TopNavBar;
