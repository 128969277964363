import * as yup from "yup";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import Modal from "@mui/material/Modal";
import { LoadingButton } from "@mui/lab";

import Title from "../../common/Title";
import PaymentInfoForm from "../../common/PaymentInfoForm";

import { config } from "../../../config/environment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function PaymentUpdateModal({
  open,
  handleClose,
  loading,
  handleSubmit,
  initialValue,
}) {
  const [formData, setFormData] = useState(null);

  const schema = yup.object({
    preferred_payment: yup.string().required("Payment option is required"),
    payment_email: yup.string().email("Please provide valid email"),
    payment_phone: yup
      .string("Phone")
      .matches(/^[0-9]+$/, "Numbers only")
      .max(10, "Phone number must be 10 digits")
      .min(10, "Phone number must be 10 digits"),
  });
  const formik = useFormik({
    initialValues: { ...initialValue },
    validationSchema: schema,

    onSubmit: (values) => {
      const requestData = Object.keys(values).reduce((obj, key) => {
        if (
          Object.keys(schema.fields).includes(key) &&
          values[key] !== null &&
          values[key]
        ) {
          obj[key] = values[key];
        }
        return obj;
      }, {});
      setFormData(requestData);
    },
    validateOnChange: true,
  });

  useEffect(() => {
    if (formData) {
      if (formData.payment_email && formData.payment_phone) {
        formik.setFieldError("payment_phone", "Please provide only one info");
        formik.setFieldError("payment_email", "Please provide only one info");
        formik.setSubmitting(false);
      } else if (!formData.payment_email && !formData.payment_phone) {
        formik.setFieldError("payment_phone", "Please provide any one info");
        formik.setFieldError("payment_email", "Please provide any one info");
        formik.setSubmitting(false);
      } else {
        handleSubmit({
          data: formData,
          params: { company_id: config.companyID },
        });
        if (!loading) {
          formik.setSubmitting(false);
        }
      }
    }
  }, [formData]);

  return (
    <Modal open={open} onClose={() => handleClose(false)}>
      <Box sx={style}>
        <Title variant="h5" content={`Update Payment Info`} gutterBottom />
        <Grid container padding={2} spacing={2}>
          <PaymentInfoForm formik={formik} />
        </Grid>
        <Grid container padding={2} spacing={2}>
          <Grid item>
            <LoadingButton
              style={{ marginRight: "5px" }}
              disabled={Boolean(Object.keys(formik.errors).length)}
              onClick={() =>
                !Object.keys(formik.errors).length &&
                !loading &&
                !formik.isSubmitting &&
                formik.submitForm()
              }
              size="large"
              loading={loading}
              variant="contained"
            >
              Update
            </LoadingButton>
          </Grid>
          <Grid item>
            <LoadingButton
              style={{ marginRight: "5px" }}
              size="large"
              onClick={() => handleClose(false)}
              loading={loading}
              variant="contained"
            >
              Cancel
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
