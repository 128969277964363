const __getClaims = {
  method: "GET",
  url: "claim/",
};

const __getClaimById = (id) => ({
  method: "GET",
  url: `claim/${id}`,
});

const __approveClaim = (id) => ({
  method: "POST",
  url: `claim/${id}`,
});

const __createClaim = {
  method: "POST",
  url: `claim/`,
};

export { __getClaims, __getClaimById, __createClaim, __approveClaim };
