import { useReducer } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import PrivateRoute from "../routes/PrivateRoutes";
import { initialAuth, authReducer } from "../reducer/auth";
import { initialAlert, alertReducer } from "../reducer/alert";
import { initialLoading, loadingReducer } from "../reducer/loading";
import { initialCompany, companyReducer } from "../reducer/company";
import {
  AuthContext,
  AlertContext,
  LoadingContext,
  CompanyContext,
} from "../context/index";

import Login from "./auth/Login";
import Home from "./views/home/Home";
import SignupForm from "./auth/Signup";
import AlertBox from "./common/AlertBox";
import LoadingBox from "./common/LoadingBox";

import StaffRoutes from "../routes/StaffRoutes";

import Setting from "./views/setting/Setting";
import SettingUpdate from "./views/setting/SettingUpdate";

import PlanRate from "./views/plan_rate/PlanRate";
import PlanRateUpdate from "./views/plan_rate/PlanRateUpdate";

import Claim from "./views/claim/Claim";
import Balance from "./views/balance/Balance";
import ClaimDetail from "./views/claim/ClaimDetail";

import CustomerList from "./views/plan_rate/CustomerList";
import CustomerInfo from "./views/customer/CustomerInfo";

import Companies from "./views/company/Company";
import AdminRoute from "../routes/SuperAdminRoute";

function App() {
  const [auth, authDispatch] = useReducer(authReducer, initialAuth);
  const [alert, alertDispatch] = useReducer(alertReducer, initialAlert);
  const [isLoading, loadingDispatch] = useReducer(
    loadingReducer,
    initialLoading
  );
  const [company, companyDispatch] = useReducer(companyReducer, initialCompany);
  return (
    <BrowserRouter>
      <AuthContext.Provider value={[auth, authDispatch]}>
        <AlertContext.Provider value={[alert, alertDispatch]}>
          <CompanyContext.Provider value={[company, companyDispatch]}>
            <LoadingContext.Provider value={[isLoading, loadingDispatch]}>
              <AlertBox />
              <LoadingBox />
              <Routes>
                <Route path="/login" exact element={<Login />} />
                <Route path="/signUp" exact element={<SignupForm />} />
                <Route path="/" element={<AdminRoute />}>
                  <Route path="/company" element={<Companies />} />

                  <Route path="/" element={<PrivateRoute />}>
                    <Route index element={<Home />} />

                    <Route path="home" element={<CustomerInfo />} />
                    <Route path="balances" element={<Balance />} />
                    <Route path="claims">
                      <Route index element={<Claim />} />
                      <Route path=":claim_id" element={<ClaimDetail />} />
                    </Route>

                    <Route path="/" element={<StaffRoutes />}>
                      <Route path="/plan_rates">
                        <Route path="customer">
                          <Route index element={<CustomerList />} />
                          <Route path=":customer_id">
                            <Route index element={<PlanRate />} />
                            <Route path=":id" element={<PlanRateUpdate />} />
                          </Route>
                        </Route>

                        <Route path="setting">
                          <Route index element={<Setting />} />
                          <Route path=":id" element={<SettingUpdate />} />
                        </Route>
                      </Route>
                    </Route>

                    <Route path="*" element={<h1>404 Error!!!</h1>} />
                  </Route>
                </Route>
              </Routes>
            </LoadingContext.Provider>
          </CompanyContext.Provider>
        </AlertContext.Provider>
      </AuthContext.Provider>
    </BrowserRouter>
  );
}

export default App;
