const __getBalances = {
  method: "GET",
  url: "subscription/",
};

const __getBalanceById = (id) => ({
  method: "GET",
  url: `subscription/${id}`,
});

const __addMissingSubscription = (customer_id) => ({
  method: "POST",
  url: `subscription/subscription/create_missing/${customer_id}`,
});

// const __update = (id) => ({
//   method: "PUT",
//   url: `subscription/${id}`,
// });

// const __generateMissing = (customer_id) => ({
//   method: "POST",
//   url: `subscription/generate_missing/${customer_id}`,
// });

export { __getBalances, __getBalanceById, __addMissingSubscription };
