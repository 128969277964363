const initialCompany = {
  companyId: 0,
};

const setCompanyId = (state, payload) => {
  return {
    ...state,
    companyId: payload.companyId,
  };
};

const companyReducer = (state, action) => {
  switch (action.type) {
    case "SET_COMPANY":
      return setCompanyId(state, action.payload);

    default:
      throw new Error();
  }
};

export { initialCompany, companyReducer };
