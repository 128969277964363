export const allItemTop = [
  {
    text: "Balances",
    to: "/balances",
  },
  {
    text: "Plan Rates",
    to: "/plan_rates",
    subMenu: [
      { text: "Customer Rates", to: "customer" },
      { text: "Setting", to: "setting" },
    ],
  },
  {
    text: "Claim",
    to: "/claims",
  },
];

export const customerItems = [
  {
    text: "Home",
    to: "/home",
  },
  {
    text: "Balances",
    to: "/balances",
  },
  {
    text: "Claim",
    to: "/claims",
  },
];
