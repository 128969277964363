import React, { useCallback, useState } from "react";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { LoadingButton } from "@mui/lab";
import {
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";

import Title from "../../common/Title";
import CustomSelect from "../../common/CustomSelect";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function ClaimApproveModal({
  open,
  handleClose,
  loading,
  handleSubmit,
  claimData,
}) {
  const [approvedAmount, setApprovedAmount] = useState(null);
  const [claimType, setClaimType] = useState("invoice");
  const [referenceNum, setReferenceNum] = useState(null);
  const [note, setNote] = useState(null);

  const checkValid = useCallback(() => {
    if (!approvedAmount || approvedAmount > claimData.claimAmount) return false;

    if (!claimType || !["invoice", "cheque"].includes(claimType)) return false;

    return true;
  }, [claimType, approvedAmount, claimData.claimAmount, referenceNum, note]);

  return (
    <Modal open={open} onClose={() => handleClose(false)}>
      <Box sx={style}>
        <Title variant="h5" content={`Approve Claim`} gutterBottom />
        {claimData.customer_preferred_payment && (
          <>
            <Typography gutterBottom component="em">
              {`Preferred Payment Option: ${claimData?.customer_preferred_payment}`}
            </Typography>
            <br />
            <Typography gutterBottom component="em">
              {`Payment Account: ${claimData?.customer_payment_info}`}
            </Typography>
          </>
        )}
        <Grid
          sx={{ flexGrow: 1 }}
          container
          spacing={2}
          direction="column"
          justifyContent="center"
          alignItems="center"
          maxWidth={350}
        >
          <Grid item xs={12} style={{ width: "100%" }}>
            <CustomSelect
              helperText={"Claim Type"}
              placeholder={"Claim Type"}
              isClearable={false}
              isSearchable={false}
              closeMenuOnSelect={true}
              options={[
                { value: "invoice", label: "Invoice" },
                { value: "cheque", label: "Cheque" },
              ]}
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
              defaultValue={{ value: claimType, label: "Invoice" }}
              handleChange={(val) => setClaimType(val.value)}
            />
          </Grid>
          <Grid item xs={12} style={{ width: "100%" }}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel htmlFor="approved_amount">Approved Amount</InputLabel>
              <OutlinedInput
                name="approved_amount"
                label="Approved Amount"
                id="approved_amount"
                value={approvedAmount}
                type="number"
                onChange={(e) => setApprovedAmount(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} style={{ width: "100%" }}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel htmlFor="reference_num">
                Reference Number (Invoice/Cheque Num)
              </InputLabel>
              <OutlinedInput
                name="reference_num"
                label="Reference Number (Invoice/Cheque Num)"
                id="reference_num"
                value={referenceNum}
                onChange={(e) => setReferenceNum(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} style={{ width: "100%" }}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel htmlFor="note">Note</InputLabel>
              <OutlinedInput
                name="note"
                label="Note"
                id="note"
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={5} justifyContent="left">
          <Grid item xs={12} style={{ width: "100%", marginTop: 10 }}>
            <LoadingButton
              variant="contained"
              color="primary"
              loading={loading}
              disabled={
                loading ||
                !checkValid() ||
                !claimData ||
                !claimData?.customer_preferred_payment
              }
              onClick={() => {
                const data = {
                  approved_amount: approvedAmount,
                  claim_type: claimType,
                  reference_num: referenceNum,
                  note: note,
                };
                handleSubmit({
                  data: data,
                });
              }}
            >
              {`Submit`}
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
