const __getCompanies = {
  method: "GET",
  url: "affiliate_customer/companies",
};

const __getCustomers = {
  method: "GET",
  url: "affiliate_customer/",
};

const __getCustomerInfo = {
  method: "GET",
  url: "affiliate_customer/info",
};

const __signUp = {
  method: "POST",
  url: "affiliate_customer/sign_up",
};

const __updatePaymentInfo = {
  method: "PUT",
  url: "affiliate_customer/update_payment",
};

export {
  __signUp,
  __getCompanies,
  __getCustomers,
  __getCustomerInfo,
  __updatePaymentInfo,
};
