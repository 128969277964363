import React from "react";
import { Link, useNavigate } from "react-router-dom";

import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";
import ListItem from "@mui/material/ListItem";
import LogoutIcon from "@mui/icons-material/Logout";
import ListItemText from "@mui/material/ListItemText";

import useAuth from "../../hooks/useAuth";
import { allItemTop, customerItems } from "../../constants/navbarList";
import { USER_TYPE } from "../../constants/value";

const SideNav = () => {
  const { user } = useAuth();
  const nav = useNavigate();

  const items = user.user_type === USER_TYPE.staff ? allItemTop : customerItems;

  const listItemTop = items.map((val) => val);
  const listItemBottom = [
    {
      text: "Logout",
      icon: <LogoutIcon />,
      hasNoLink: true,
      onclick: () => {
        localStorage.clear();
        nav("/login");
      },
    },
  ];

  return (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {listItemTop.map((list) => (
          <ListItem button key={list.text} component={Link} to={list.to}>
            {/* <ListItemIcon>{list.icon}</ListItemIcon> */}
            <ListItemText primary={list.text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {listItemBottom.map((list) =>
          !list.hasNoLink ? (
            <ListItem button key={list.text} component={Link} to={list.to}>
              <ListItemText primary={list.text} />
            </ListItem>
          ) : (
            <ListItem button key={list.text} onClick={list.onclick}>
              <ListItemText primary={list.text} />
            </ListItem>
          )
        )}
      </List>
    </div>
  );
};

export default SideNav;
