import React from "react";

import DataTable from "../../common/DataTable";
import useAuth from "../../../hooks/useAuth";
import { USER_TYPE } from "../../../constants/value";

const columns = [
  { field: "id", headerName: "ID", flex: 1 },
  {
    field: "affiliate_customer_id",
    headerName: "Customer ID",
    flex: 1,
  },
  {
    field: "affiliate_customer_name",
    headerName: "Customer Name",
    flex: 2,
    valueGetter: (params) => params.row?.affiliate_customer?.name,
  },
  {
    field: "amount",
    headerName: "Amount",
    flex: 1,
  },
  {
    field: "claimable",
    headerName: "Claimable",
    flex: 1,
    filterable: false,
    sortable: false,
  },
  {
    field: "claimed",
    headerName: "Claimed",
    flex: 1,
  },
  {
    field: "claim_id",
    headerName: "Claim ID",
    flex: 1,
  },
  {
    field: "created_at",
    headerName: "Created Date",
    flex: 1,
    valueGetter: (params) =>
      params.row?.created_at && `${params.row?.created_at.toLocaleString()}`,
  },
];

const customerColumns = columns.filter(
  (val) => !val?.field?.startsWith("affiliate_customer")
);

const BalanceList = ({
  data,
  client,
  loading,
  metaData,
  handleSort,
  handleRowClick,
  handlePageChange,
  checkboxSelection,
  handlePageSizeChange,
  handlePaginationModelChange,
  ...props
}) => {
  const { user } = useAuth();
  return (
    <DataTable
      data={data}
      client={client}
      loading={loading}
      columns={
        user.user_type === USER_TYPE.staff && !client
          ? columns
          : customerColumns
      }
      disableRowSelectionOnClick={true}
      checkboxSelection={checkboxSelection}
      metaData={metaData}
      isRowSelectable={(params) => params.row.claimable && !params.row.claimed}
      handleSort={handleSort}
      handleRowClick={handleRowClick}
      handlePaginationModelChange={handlePaginationModelChange}
      {...props}
    />
  );
};

export default BalanceList;
