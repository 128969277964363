import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box } from "@mui/system";

import Title from "../../common/Title";
import DataTable from "../../common/DataTable";
import useAxios from "../../../hooks/useAxios";
import { Button, Grid, Stack } from "@mui/material";

import {
  __getRateSettings,
  __generateMissingRateSetting,
} from "../../../api/plan_rate_setting";
import useAlert from "../../../hooks/useAlert";

const Setting = () => {
  const columns = [
    { field: "id", headerName: "ID", flex: 1 },
    {
      field: "plan_id",
      headerName: "Plan ID",
      flex: 1,
    },
    {
      field: "plan.name",
      headerName: "Plan",
      flex: 2,
      valueGetter: (params) => params.row?.plan?.name || "-",
    },
    {
      field: "affiliate_discount",
      headerName: "Affiliate Discount",
      flex: 1,
    },
    {
      field: "sub_affiliate_discount",
      headerName: "Sub-Affiliate Discount",
      flex: 1,
    },
    {
      field: "total_billing_cycle",
      headerName: "Total Billing Cycle",
      flex: 1,
    },
  ];

  const nav = useNavigate();
  const { setAlertMessage } = useAlert();
  const [data, setData] = useState([]);

  const [response, call, loading] = useAxios({ ...__getRateSettings });
  const [regenerateResponse, regenerateCall, regenerateLoading] = useAxios({
    ...__generateMissingRateSetting,
  });

  useEffect(() => {
    call({ params: { page: 1, page_size: 1000 } });
  }, []);

  useEffect(() => {
    if (response && response.status) {
      setData(response.data.data);
    }
  }, [response]);

  useEffect(() => {
    if (regenerateResponse && regenerateResponse.status) {
      setAlertMessage({
        type: "success",
        message: `Generated missing plan settings.`,
      });
      call({ params: { page: 1, page_size: 1000 } });
    }
  }, [regenerateResponse]);

  return (
    <Box sx={{ width: "100%" }}>
      <Title variant="h5" content={`Plan Settings`} gutterBottom />
      <Grid container justifyContent="flex-start" spacing={2}>
        <Grid item sx={{ mb: "10px" }}>
          <Stack spacing={2} direction="row">
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={() => regenerateCall()}
            >
              Generate Missing Plans
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <DataTable
        disableColumnFilter
        loading={loading}
        data={data}
        columns={columns}
        filterMode={"client"}
        client={true}
        metaData={{ page: 1, page_size: 100 }}
        handleRowClick={(params) => nav(`${params.id}`)}
      />
    </Box>
  );
};

export default Setting;
