import { useContext } from "react";

import { CompanyContext } from "../context/index";

const useCompany = () => {
  const [company, companyDispatch] = useContext(CompanyContext);
  const { companyId } = company;

  const setCompanyId = (data) => {
    companyDispatch({
      type: "SET_COMPANY",
      payload: data,
    });
  };

  return { companyId, setCompanyId };
};

export default useCompany;
