import React from "react";
import { Navigate } from "react-router-dom";

import useAuth from "../../../hooks/useAuth";
import { USER_TYPE } from "../../../constants/value";

const Home = () => {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (user.user_type === USER_TYPE.staff && user.company_id === 0) {
    return <Navigate to="/company" />;
  }

  if (user.user_type === USER_TYPE.staff) {
    return <Navigate to="/balances" />;
  }

  return <Navigate to="/home" />;
};

export default Home;
