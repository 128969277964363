import * as yup from "yup";
import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import {
  Box,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";

import PaymentInfoForm from "../common/PaymentInfoForm";

import useAuth from "../../hooks/useAuth";
import useAxios from "../../hooks/useAxios";
import useAlert from "../../hooks/useAlert";
import { __signUp } from "../../api/customer";
import { config } from "../../config/environment";
import { STATE_LIST } from "../../constants/value";

const SignupForm = () => {
  const nav = useNavigate();
  const { auth } = useAuth();
  const [queryParams] = useSearchParams();
  const { setAlertMessage } = useAlert();
  const referral_code = queryParams.get("referral_code");

  const [formData, setFormData] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [passwordDiffError, setPasswordDiffError] = useState(null);

  const [response, call, loading] = useAxios({ ...__signUp });

  const passwordErrorMessage = "Provided password doesn't match. Please verify";

  const schema = yup.object({
    fname: yup.string("First Name").required("Please provide your first name"),
    lname: yup.string("Last Name").required("Please provide your last name"),
    email: yup
      .string("Email")
      .email("Please provide a valid email")
      .required("Please provide your email"),
    password: yup
      .string("Password")
      .min(6, "Password must be at least 6 characters long")
      .required("Please enter your password "),
    phone: yup
      .string("Phone")
      .matches(/^[0-9]+$/, "Numbers only")
      .max(10, "Phone number must be 10 digits")
      .min(10, "Phone number must be 10 digits")
      .required("Please provide your phone number"),
    pin: yup
      .string("Pin")
      .matches(/^[0-9]+$/, "Numbers only")
      .min(4, "Pin must be 4 digits")
      .max(4, "Pin must be 4 digits")
      .required("Please provide your 4 digit pin"),

    referral_code: yup.string("Referral Code"),

    address: yup.string("Address").required("Please provide your address"),
    city: yup.string("City").required("Please provide your city"),
    state: yup.string("State").required("Please provide your state"),
    zip: yup
      .string("Zip")
      .matches(/^[0-9]+$/, "Numbers only")
      .min(4, "Please provide a valid zip")
      .max(5, "Please provide a valid zip")
      .required("Please provide your zip code"),

    preferred_payment: yup.string().required("Payment option is required"),
    payment_email: yup.string().email("Please provide valid email"),
    payment_phone: yup
      .string("Phone")
      .matches(/^[0-9]+$/, "Numbers only")
      .max(10, "Phone number must be 10 digits")
      .min(10, "Phone number must be 10 digits"),
  });

  const initialValues = {
    fname: "",
    lname: "",
    email: "",
    password: "",
    phone: "",
    pin: "",
    referral_code: "",
    address: "",
    city: "",
    state: "",
    zip: "",
  };

  const formik = useFormik({
    initialValues: referral_code
      ? { ...initialValues, referral_code: referral_code }
      : { ...initialValues },

    validationSchema: schema,
    onSubmit: (values) => {
      const requestData = Object.keys(values).reduce((obj, key) => {
        if (
          Object.keys(schema.fields).includes(key) &&
          values[key] !== null &&
          values[key] !== ""
        ) {
          obj[key] = values[key];
        }
        return obj;
      }, {});

      setFormData(requestData);
    },
    validateOnChange: true,
  });

  useEffect(() => {
    if (response && response.status) {
      setAlertMessage({
        type: "success",
        message: "Sign up Success!. Login with your email and password",
      });
      nav("/login");
    }
  }, [response]);

  useEffect(() => {
    if (
      formik.values.password &&
      confirmPassword &&
      formik.values.password !== confirmPassword
    ) {
      setPasswordDiffError(true);
    }
    if (
      formik.values.password &&
      confirmPassword &&
      formik.values.password === confirmPassword
    ) {
      setPasswordDiffError(false);
    }
  }, [confirmPassword, formik.values.password]);

  useEffect(() => {
    if (formData) {
      if (formData.payment_email && formData.payment_phone) {
        formik.setFieldError("payment_phone", "Please provide only one info");
        formik.setFieldError("payment_email", "Please provide only one info");
        formik.setSubmitting(false);
      } else if (!formData.payment_email && !formData.payment_phone) {
        formik.setFieldError("payment_phone", "Please provide any one info");
        formik.setFieldError("payment_email", "Please provide any one info");
        formik.setSubmitting(false);
      } else {
        call({
          data: formData,
          params: { company_id: config.companyID },
        });
        if (!loading) {
          formik.setSubmitting(false);
        }
      }
    }
  }, [formData]);

  if (auth && auth.token) {
    return <Navigate to="/" />;
  }

  return (
    <Grid sx={{ maxWidth: "750px", mx: "auto" }}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        padding={2}
      >
        <Box
          component="form"
          sx={{
            width: "100%",
            boxShadow: 3,
            borderRadius: 2,
            p: 4,
          }}
          noValidate
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <Typography
            variant="h4"
            gutterBottom
            component="div"
            sx={{ textAlign: "center" }}
          >
            SIGN UP
          </Typography>
          <Grid container padding={2} spacing={2}>
            <Grid item xs={6} sx={{ mb: 3 }}>
              <FormControl
                error={
                  formik.touched.fname && Boolean(formik.errors.fname)
                    ? true
                    : false
                }
                sx={{ width: "100%" }}
              >
                <InputLabel htmlFor="fname">First Name</InputLabel>
                <OutlinedInput
                  name="fname"
                  label="First Name"
                  id="fname"
                  value={formik.values.fname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.fname && Boolean(formik.errors.fname) ? (
                  <FormHelperText id="fname-error-text">
                    {formik.errors.fname}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
            <Grid item xs={6} sx={{ mb: 3 }}>
              <FormControl
                error={
                  formik.touched.lname && Boolean(formik.errors.lname)
                    ? true
                    : false
                }
                sx={{ width: "100%" }}
              >
                <InputLabel htmlFor="lname">Last Name</InputLabel>
                <OutlinedInput
                  name="lname"
                  label="Last Name"
                  id="lname"
                  value={formik.values.lname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.lname && Boolean(formik.errors.lname) ? (
                  <FormHelperText id="lname-error-text">
                    {formik.errors.lname}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <FormControl
                error={
                  formik.touched.email && Boolean(formik.errors.email)
                    ? true
                    : false
                }
                sx={{ width: "100%" }}
              >
                <InputLabel htmlFor="email">Email</InputLabel>
                <OutlinedInput
                  name="email"
                  label="Email"
                  id="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.email && Boolean(formik.errors.email) ? (
                  <FormHelperText id="email-error-text">
                    {formik.errors.email}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>

            <Grid item xs={6} sx={{ mb: 3 }}>
              <FormControl
                error={
                  (formik.touched.password &&
                    Boolean(formik.errors.password)) ||
                  passwordDiffError
                    ? true
                    : false
                }
                sx={{ width: "100%" }}
              >
                <InputLabel htmlFor="password">Password</InputLabel>
                <OutlinedInput
                  type="password"
                  name="password"
                  label="Password"
                  id="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.password && Boolean(formik.errors.password) ? (
                  <FormHelperText id="password-error-text">
                    {formik.errors.password}
                  </FormHelperText>
                ) : formik.touched.password && passwordDiffError ? (
                  <FormHelperText id="confirmPassword-error-text">
                    {passwordErrorMessage}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
            <Grid item xs={6} sx={{ mb: 3 }}>
              <FormControl error={passwordDiffError} sx={{ width: "100%" }}>
                <InputLabel htmlFor="confirmPassword">
                  Confirm Password
                </InputLabel>
                <OutlinedInput
                  type="password"
                  name="confirmPassword"
                  label="Confirm Password"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.password && passwordDiffError ? (
                  <FormHelperText id="confirmPassword-error-text">
                    {passwordErrorMessage}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
            <Grid item xs={6} sx={{ mb: 3 }}>
              <FormControl
                error={
                  formik.touched.phone && Boolean(formik.errors.phone)
                    ? true
                    : false
                }
                sx={{ width: "100%" }}
              >
                <InputLabel htmlFor="phone">Phone</InputLabel>
                <OutlinedInput
                  type="number"
                  name="phone"
                  label="Phone"
                  id="phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.phone && Boolean(formik.errors.phone) ? (
                  <FormHelperText id="phone-error-text">
                    {formik.errors.phone}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
            <Grid item xs={6} sx={{ mb: 3 }}>
              <FormControl
                error={
                  formik.touched.pin && Boolean(formik.errors.pin)
                    ? true
                    : false
                }
                sx={{ width: "100%" }}
              >
                <InputLabel htmlFor="pin">Pin (4-digits)</InputLabel>
                <OutlinedInput
                  type="number"
                  name="pin"
                  label="Pin (4-digits)"
                  id="pin"
                  value={formik.values.pin}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.pin && Boolean(formik.errors.pin) ? (
                  <FormHelperText id="pin-error-text">
                    {formik.errors.pin}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
            <Grid item xs={6} sx={{ mb: 3 }}>
              <FormControl
                error={
                  formik.touched.address && Boolean(formik.errors.address)
                    ? true
                    : false
                }
                sx={{ width: "100%" }}
              >
                <InputLabel htmlFor="address">Address</InputLabel>
                <OutlinedInput
                  name="address"
                  label="Address"
                  id="address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.address && Boolean(formik.errors.address) ? (
                  <FormHelperText id="address-error-text">
                    {formik.errors.address}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
            <Grid item xs={6} sx={{ mb: 3 }}>
              <FormControl
                error={
                  formik.touched.city && Boolean(formik.errors.city)
                    ? true
                    : false
                }
                sx={{ width: "100%" }}
              >
                <InputLabel htmlFor="city">City</InputLabel>
                <OutlinedInput
                  name="city"
                  label="City"
                  id="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.city && Boolean(formik.errors.city) ? (
                  <FormHelperText id="city-error-text">
                    {formik.errors.city}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
            <Grid item xs={6} sx={{ mb: 3 }}>
              <FormControl
                error={
                  formik.touched.zip && Boolean(formik.errors.zip)
                    ? true
                    : false
                }
                sx={{ width: "100%" }}
              >
                <InputLabel htmlFor="zip">Zip</InputLabel>
                <OutlinedInput
                  type="number"
                  name="zip"
                  label="Zip"
                  id="zip"
                  value={formik.values.zip}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.zip && Boolean(formik.errors.zip) ? (
                  <FormHelperText id="zip-error-text">
                    {formik.errors.zip}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <FormControl fullWidth>
                <InputLabel id="state">State</InputLabel>
                <Select
                  labelId="state"
                  id="state"
                  value={formik.values.state}
                  label="State"
                  onChange={(e) =>
                    formik.setFieldValue("state", e.target.value)
                  }
                  onBlur={formik.handleBlur}
                >
                  {STATE_LIST.map((val) => (
                    <MenuItem key={val.value} value={val.value}>
                      {val.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <PaymentInfoForm formik={formik} />
            <Grid item xs={6} sx={{ mb: 3 }}>
              <FormControl
                error={
                  formik.touched.referral_code &&
                  Boolean(formik.errors.referral_code)
                    ? true
                    : false
                }
                sx={{ width: "100%" }}
              >
                <InputLabel htmlFor="referral_code">Referral Code</InputLabel>
                <OutlinedInput
                  name="referral_code"
                  label="Referral Code"
                  id="referral_code"
                  value={formik.values.referral_code}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.referral_code &&
                Boolean(formik.errors.referral_code) ? (
                  <FormHelperText id="referral_code-error-text">
                    {formik.errors.referral_code}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container padding={2} spacing={2}>
            <Grid item>
              <LoadingButton
                style={{ marginRight: "5px" }}
                disabled={
                  Boolean(Object.keys(formik.errors).length) ||
                  passwordDiffError
                }
                onClick={() =>
                  !Object.keys(formik.errors).length &&
                  !passwordDiffError &&
                  !loading &&
                  !formik.isSubmitting &&
                  formik.submitForm()
                }
                size="large"
                loading={loading}
                variant="contained"
              >
                Sign Up
              </LoadingButton>
            </Grid>
            <Grid item>
              <LoadingButton
                style={{ marginRight: "5px" }}
                size="large"
                onClick={() => nav(`/login`)}
                loading={loading}
                variant="contained"
              >
                Cancel
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SignupForm;
