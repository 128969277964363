import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { Box } from "@mui/system";
import { FormControl, Grid, Stack } from "@mui/material";

import Title from "../../common/Title";
import DataTable from "../../common/DataTable";
import CustomSelect from "../../common/CustomSelect";

import useAuth from "../../../hooks/useAuth";
import useAxios from "../../../hooks/useAxios";
import { __getClaims } from "../../../api/claim";
import { USER_TYPE } from "../../../constants/value";
import { __getCustomers } from "../../../api/customer";

const columns = [
  { field: "id", headerName: "ID", flex: 0.5 },
  {
    field: "claiming_customer_name",
    headerName: "Claiming Customer Name",
    flex: 1,
    valueGetter: (params) => params.row?.claiming_customer?.name,
  },
  {
    field: "claim_amount",
    headerName: "Claim Amount",
    flex: 1,
  },
  {
    field: "claim_date",
    headerName: "Claim Date",
    flex: 1,
    valueGetter: (params) =>
      params.row?.claim_date && `${params.row?.claim_date.toLocaleString()}`,
  },
  {
    field: "approved_amount",
    headerName: "Approved Amount",
    flex: 1,
  },
  {
    field: "approved_date",
    headerName: "Approved Date",
    flex: 1,
    valueGetter: (params) =>
      params.row?.approved_date &&
      `${params.row?.approved_date.toLocaleString()}`,
  },
  {
    field: "approved_by",
    headerName: "Approved By",
    flex: 1,
    valueGetter: (params) => params.row?.approved_staff?.name,
  },
];

const customerExcludeColumns = ["claiming_customer_name", "approved_by"];

const customerColumns = columns.filter(
  (val) => !customerExcludeColumns.includes(val?.field)
);

const Claim = () => {
  const nav = useNavigate();
  const { user } = useAuth();
  const staff = user?.user_type === USER_TYPE.staff;

  const [customers, setCustomers] = useState([
    { label: "Select Customer", value: null },
  ]);
  const [currentCustomer, setCurrentCustomer] = useState(null);

  const [data, setData] = useState([]);
  const [metaData, setMetaData] = useState({
    total: 0,
    page: 1,
    page_size: 100,
  });
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0,
  });
  const [sortQuery, setSortQuery] = useState();

  const [response, call, loading] = useAxios({ ...__getClaims });
  const [customerResponse, customerCall, customerLoading] = useAxios({
    ...__getCustomers,
  });

  useEffect(() => {
    if (staff) customerCall({ params: { page_size: 1000 } });
  }, []);

  useEffect(() => {
    const params = {
      page: paginationModel.page + 1,
      page_size: paginationModel.pageSize,
      sort: sortQuery,
    };

    if (staff) {
      params.claiming_customer_id = currentCustomer;
    }

    call({ params });
  }, [paginationModel, sortQuery, currentCustomer]);

  useEffect(() => {
    if (response && response.status) {
      setData(response.data.data);
      setMetaData({
        total: 100,
        page_size: response.data.page_size,
        page: response.data.page,
      });
    }
  }, [response]);

  useEffect(() => {
    if (customerResponse && customerResponse.status) {
      setCustomers([
        { label: "Select Customer", value: null },
        ...customerResponse.data.data.map((val) => ({
          label: `${val?.customer?.name || "-"}`,
          value: val?.customer?.id || "-",
        })),
      ]);
    }
  }, [customerResponse]);

  return (
    <Box sx={{ width: "100%" }}>
      <Title variant="h5" content={`Claims`} gutterBottom />
      {staff && (
        <Grid container justifyContent="flex-start">
          <Grid item sx={{ mb: "10px" }}>
            <Stack direction="row">
              <FormControl sx={{ width: "100%" }}>
                <CustomSelect
                  helperText={"Customer"}
                  ltextFieldProps={{
                    label: "Label",
                    InputLabelProps: {
                      shrink: true,
                    },
                  }}
                  placeholder={"Customer"}
                  isClearable={false}
                  isSearchable={false}
                  closeMenuOnSelect={true}
                  options={customers}
                  defaultValue={customers[0]}
                  handleChange={(val) => setCurrentCustomer(val.value)}
                />
              </FormControl>
            </Stack>
          </Grid>
        </Grid>
      )}
      <DataTable
        loading={loading}
        data={data}
        columns={
          user?.user_type === USER_TYPE.staff ? columns : customerColumns
        }
        metaData={metaData}
        handleRowClick={(params) => nav(`${params.row?.id}`)}
        handlePaginationModelChange={setPaginationModel}
        handleSort={setSortQuery}
      />
    </Box>
  );
};

export default Claim;
