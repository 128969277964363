import { Navigate, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react";

import * as yup from "yup";
import { useFormik } from "formik";

import { __login } from "../../api/auth";
import useAuth from "../../hooks/useAuth";
import useAxios from "../../hooks/useAxios";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import { config } from "../../config/environment";

const Login = () => {
  const nav = useNavigate();
  const form = { username: "", password: "" };
  const [details, setDetails] = useState("");
  const [formData, setFormData] = useState(null);
  const { auth, setUserData, setAccessTokenData, setRefreshTokenData } =
    useAuth();

  const validationSchema = yup.object({
    username: yup
      .string("Enter your password")
      .required("Username is required"),
    password: yup
      .string("Enter your password")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      ...form,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const data = new FormData();
      Object.keys(values).forEach((key) => data.append(key, values[key]));
      setFormData(data);
    },
  });

  const [response, call, loading] = useAxios({
    ...__login,
    data: formData,
  });

  const authentication = useCallback(() => {
    setAccessTokenData(response.data.access_token);
    setRefreshTokenData(response.data.refresh_token);
    setUserData(response.data.user);
  }, [response]);

  useEffect(() => {
    if (!response) return;
    if (response.status) {
      setTimeout(() => {
        authentication();
      }, 10);
    } else if (response?.data?.detail) {
      setDetails(response.data.detail);
    }
  }, [response]);

  useEffect(() => {
    if (formData) {
      setDetails(null);
      call({
        params: {
          company_id: config.companyID,
        },
      });
    }
  }, [formData]);

  if (auth.token) {
    return <Navigate to="/" />;
  }

  return (
    <Grid sx={{ maxWidth: "460px", mx: "auto" }}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        padding={2}
      >
        <Box
          component="form"
          sx={{
            width: "100%",
            boxShadow: 3,
            borderRadius: 2,
            p: 4,
          }}
          noValidate
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <Typography
            variant="h4"
            gutterBottom
            component="div"
            sx={{ textAlign: "center" }}
          >
            LOGIN
          </Typography>
          <Grid>
            <Grid item xs={12} sx={{ mb: 3, mt: 2 }}>
              <FormControl
                error={
                  formik.touched.username && Boolean(formik.errors.username)
                    ? true
                    : false
                }
                sx={{ width: "100%" }}
              >
                <InputLabel htmlFor="username">Username</InputLabel>
                <OutlinedInput
                  name="username"
                  label="Username"
                  id="username"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                />
                {formik.touched.username && Boolean(formik.errors.username) ? (
                  <FormHelperText id="username-error-text">
                    {formik.errors.username}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <FormControl
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                    ? true
                    : false
                }
                sx={{ width: "100%" }}
              >
                <InputLabel htmlFor="password">Password</InputLabel>
                <OutlinedInput
                  type="password"
                  name="password"
                  label="Password"
                  id="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                />
                {formik.touched.password && Boolean(formik.errors.password) ? (
                  <FormHelperText id="password-error-text">
                    {formik.errors.password}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
              <FormControl
                error={details ? true : false}
                sx={{ width: "100%", textAlign: "center" }}
              >
                <FormHelperText id="login-error-text">{details}</FormHelperText>
              </FormControl>
            </Grid>
            <Stack direction="row" spacing={2}>
              <LoadingButton
                type="submit"
                size="large"
                loading={loading}
                variant="contained"
              >
                Login
              </LoadingButton>
              <LoadingButton
                type="button"
                size="large"
                loading={loading}
                variant="contained"
                onClick={() => nav("/signUp")}
              >
                Sign Up
              </LoadingButton>
            </Stack>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
