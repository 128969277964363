import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { Box } from "@mui/system";
import { Button, FormControl, Grid, Stack } from "@mui/material";

import Title from "../../common/Title";
import BalanceList from "./BalanceList";
import CustomSelect from "../../common/CustomSelect";

import useAuth from "../../../hooks/useAuth";
import useAlert from "../../../hooks/useAlert";
import useAxios from "../../../hooks/useAxios";
import { __createClaim } from "../../../api/claim";
import { __getBalances } from "../../../api/balance";
import { USER_TYPE } from "../../../constants/value";
import { __getCustomers } from "../../../api/customer";

const Balance = () => {
  const nav = useNavigate();
  const { user } = useAuth();
  const staff = user?.user_type === USER_TYPE.staff;
  const { setAlertMessage } = useAlert();

  const [customers, setCustomers] = useState([
    { label: "Select Customer", value: null },
  ]);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [data, setData] = useState([]);
  const [metaData, setMetaData] = useState({
    total: 0,
    page: 1,
    page_size: 100,
  });

  const [selectedRows, setSelectedRows] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0,
  });
  const [sortQuery, setSortQuery] = useState();

  const [response, call, loading] = useAxios({ ...__getBalances });
  const [claimResponse, claimCall, claimLoading] = useAxios({
    ...__createClaim,
  });
  const [customerResponse, customerCall, customerLoading] = useAxios({
    ...__getCustomers,
  });

  useEffect(() => {
    if (staff) customerCall({ params: { page_size: 1000 } });
  }, []);

  useEffect(() => {
    const params = {
      page: paginationModel.page + 1,
      page_size: paginationModel.pageSize,
      sort: sortQuery,
    };

    if (staff) {
      params.customer_id = currentCustomer;
    }

    call({ params });
  }, [paginationModel, sortQuery, currentCustomer]);

  useEffect(() => {
    if (claimResponse && claimResponse.status) {
      setAlertMessage({ type: "success", message: `New Claim generated` });
      nav(`/claims/${claimResponse.data.id}`);
    }
  }, [claimResponse]);

  useEffect(() => {
    if (response && response.status) {
      setData(response.data.data);
      setMetaData({
        total: response.data.total,
        page_size: response.data.page_size,
        page: response.data.page,
      });
    }
  }, [response]);

  useEffect(() => {
    if (customerResponse && customerResponse.status) {
      setCustomers([
        { label: "Select Customer", value: null },
        ...customerResponse.data.data.map((val) => ({
          label: `${val.customer?.name || '-'}`,
          value: val.customer?.id || '-',
        })),
      ]);
    }
  }, [customerResponse]);

  return (
    <Box sx={{ width: "100%" }}>
      <Title variant="h5" content={`Balances`} gutterBottom />
      {!staff && (
        <Grid container justifyContent="flex-start" spacing={2}>
          <Grid item sx={{ mb: "10px" }}>
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                color="primary"
                disabled={
                  loading ||
                  claimLoading ||
                  customerLoading ||
                  !(selectedRows && selectedRows.length)
                }
                onClick={() =>
                  claimCall({ data: { balance_ids: selectedRows } })
                }
              >
                Create Claims
              </Button>
            </Stack>
          </Grid>
        </Grid>
      )}
      {staff && (
        <Grid container justifyContent="flex-start">
          <Grid item sx={{ mb: "10px" }}>
            <Stack direction="row">
              <FormControl sx={{ width: "100%" }}>
                <CustomSelect
                  helperText={"Customer"}
                  ltextFieldProps={{
                    label: "Label",
                    InputLabelProps: {
                      shrink: true,
                    },
                  }}
                  placeholder={"Customer"}
                  isClearable={false}
                  isSearchable={false}
                  closeMenuOnSelect={true}
                  options={customers}
                  defaultValue={customers[0]}
                  handleChange={(val) => setCurrentCustomer(val.value)}
                />
              </FormControl>
            </Stack>
          </Grid>
        </Grid>
      )}
      <BalanceList
        loading={loading}
        data={data}
        metaData={metaData}
        handlePaginationModelChange={setPaginationModel}
        handleSort={setSortQuery}
        client={false}
        checkboxSelection={!staff}
        onRowSelectionModelChange={(newRowSelectionModel) =>
          setSelectedRows(newRowSelectionModel)
        }
      />
    </Box>
  );
};

export default Balance;
