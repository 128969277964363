import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import useAuth from "../hooks/useAuth";
import useCompany from "../hooks/useCompany";
import { USER_TYPE } from "../constants/value";
import { config } from "../config/environment";

const AdminRoute = () => {
  const { user, auth } = useAuth();
  const { companyId } = useCompany();
  if (
    !auth.token ||
    !user ||
    (config.userType && user.user_type !== config.userType) ||
    (user.company_id !== 0 &&
      config.companyID &&
      user.company_id !== parseInt(config.companyID))
  ) {
    localStorage.clear();
    return <Navigate to="/login" />;
  }
  if (
    user.user_type === USER_TYPE.staff &&
    user.company_id === 0 &&
    companyId === 0
  ) {
    return <Navigate to="/company" />;
  }

  return <Outlet />;
};

export default AdminRoute;
