import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import useAuth from "../hooks/useAuth";
import { USER_TYPE } from "../constants/value";
import useCompany from "../hooks/useCompany";

const StaffRoutes = () => {
  const { auth, user } = useAuth();
  if (!auth.token) {
    return <Navigate to="/login" />;
  }
  if (user.user_type !== USER_TYPE.staff) {
    return <Navigate to="/" />;
  }
  return <Outlet />;
};

export default StaffRoutes;
