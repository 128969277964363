const __getRateSettings = {
  method: "GET",
  url: "plan_rate_setting/",
};

const __getRateSettingById = (id) => ({
  method: "GET",
  url: `plan_rate_setting/${id}`,
});

const __updateRateSetting = (id) => ({
  method: "PUT",
  url: `plan_rate_setting/${id}`,
});

const __generateMissingRateSetting = {
  method: "POST",
  url: `plan_rate_setting/generate_missing`,
};

export {
  __getRateSettings,
  __generateMissingRateSetting,
  __getRateSettingById,
  __updateRateSetting,
};
