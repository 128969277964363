import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box } from "@mui/system";

import Title from "../../common/Title";
import DataTable from "../../common/DataTable";
import useAxios from "../../../hooks/useAxios";

import { __getCustomers } from "../../../api/customer";

const CustomerList = () => {
  const columns = [
    {
      field: "customer_id",
      headerName: "Customer ID",
      flex: 1,
    },
    {
      field: "customer.name",
      headerName: "Customer",
      flex: 2,
      valueGetter: (params) => params.row?.customer?.name || "-",
    },
    {
      field: "code",
      headerName: "Referral Code",
      flex: 1,
    },
  ];

  const nav = useNavigate();
  const [data, setData] = useState([]);
  const [metaData, setMetaData] = useState({
    total: 0,
    page: 1,
    page_size: 100,
  });
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0,
  });

  const [sortQuery, setSortQuery] = useState();

  const [response, call, loading] = useAxios({ ...__getCustomers });

  useEffect(() => {
    call({
      params: {
        page: paginationModel.page + 1,
        page_size: paginationModel.pageSize,
        sort: sortQuery,
      },
    });
  }, [paginationModel, sortQuery]);

  useEffect(() => {
    if (response && response.status) {
      setData(response.data.data);
      setMetaData({
        total: response.data.total,
        page_size: response.data.page_size,
        page: response.data.page,
      });
    }
  }, [response]);

  return (
    <Box sx={{ width: "100%" }}>
      <Title variant="h5" content={`Customers`} gutterBottom />
      <DataTable
        loading={loading}
        data={data}
        columns={columns}
        metaData={metaData}
        handleRowClick={(params) => nav(`${params.row?.customer_id}`)}
        handlePaginationModelChange={setPaginationModel}
        handleSort={setSortQuery}
      />
    </Box>
  );
};

export default CustomerList;
