import * as React from "react";

import FormControl from "@mui/material/FormControl";
import {
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
} from "@mui/material";

import { PAYMENT_OPTIONS } from "../../constants/value";

export default function PaymentInfoForm({ formik }) {
  return (
    <>
      <Grid item xs={6} sx={{ mb: 3 }}>
        <FormControl>
          <FormLabel id="preferred_payment">Preferred Payment</FormLabel>
          <RadioGroup
            aria-labelledby="preferred_payment"
            name="preferred_payment"
            value={formik.values.preferred_payment}
            onChange={(e) => {
              formik.setFieldValue("payment_email", "");
              formik.setFieldValue("payment_phone", "");
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
          >
            {PAYMENT_OPTIONS.map((val) => (
              <FormControlLabel
                value={val.value}
                control={<Radio />}
                label={val.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={6} sx={{ mb: 3 }}>
        {formik.values.preferred_payment && (
          <FormControl
            error={
              formik.touched.payment_email &&
              Boolean(formik.errors.payment_email)
                ? true
                : false
            }
            sx={{ width: "100%", mb: 1 }}
          >
            <InputLabel htmlFor="payment_email">
              Payment Account Email
            </InputLabel>
            <OutlinedInput
              name="payment_email"
              label="Payment Account Email"
              id="payment_email"
              value={formik.values.payment_email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.payment_email &&
            Boolean(formik.errors.payment_email) ? (
              <FormHelperText id="payment_email-error-text">
                {formik.errors.payment_email}
              </FormHelperText>
            ) : (
              ""
            )}
          </FormControl>
        )}
        {formik.values.preferred_payment &&
          formik.values.preferred_payment === "zelle" && (
            <FormControl
              error={
                formik.touched.payment_phone &&
                Boolean(formik.errors.payment_phone)
                  ? true
                  : false
              }
              sx={{ width: "100%" }}
            >
              <InputLabel htmlFor="payment_phone">
                Payment Account Phone Number
              </InputLabel>
              <OutlinedInput
                type="number"
                name="payment_phone"
                label="Payment Account Phone Number"
                id="payment_phone"
                value={formik.values.payment_phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.payment_phone &&
              Boolean(formik.errors.payment_phone) ? (
                <FormHelperText id="payment_phone-error-text">
                  {formik.errors.payment_phone}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          )}
      </Grid>
    </>
  );
}
