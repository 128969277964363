import React from "react";
import { Navigate } from "react-router-dom";

import useAuth from "../hooks/useAuth";
import Dashboard from "../components/layout/Dashboard";

const AdminRoute = () => {
  const { auth } = useAuth();
  if (!auth.token) {
    return <Navigate to="/login" />;
  }

  return <Dashboard />;
};

export default AdminRoute;
