import { alpha, styled } from "@mui/material/styles";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import React, { useCallback, useEffect, useState } from "react";

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`& .${gridClasses.row}`]: {
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      cursor: "pointer",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

const DataTable = ({
  data,
  client,
  columns,
  loading,
  metaData,
  handleSort,
  handleRowClick,
  handlePageChange,
  checkboxSelection,
  handlePageSizeChange,
  handlePaginationModelChange,
  ...props
}) => {
  const [rows, setRows] = useState([]);
  useEffect(() => {
    setRows(data.map((each) => each));
  }, [data]);

  const handleSorting = useCallback((sortModel) => {
    sortModel &&
      handleSort({
        sort_field: sortModel[0]?.field,
        sort_dir: sortModel[0]?.sort,
      });
  }, []);

  return client ? (
    <StripedDataGrid
      rows={rows}
      loading={loading}
      columns={columns}
      autoHeight={true}
      rowHeight={52}
      pageSize={metaData.page_size}
      rowCount={metaData.total}
      pageSizeOptions={[5, 10, 20, 50, 100]}
      disableSelectionOnClick
      checkboxSelection={checkboxSelection}
      onRowClick={(e, details) => (handleRowClick ? handleRowClick(e) : null)}
      getRowClassName={(params) =>
        params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
      }
      filterMode={client ? "client" : "server"}
      paginationMode={client ? "client" : "server"}
      sortingMode={client ? "client" : "server"}
      {...props}
    />
  ) : (
    <StripedDataGrid
      rows={rows}
      loading={loading}
      columns={columns}
      autoHeight={true}
      rowHeight={52}
      page={metaData.page - 1}
      pageSize={metaData.page_size}
      onPageChange={(newPage) => handlePageChange(newPage + 1)}
      onPaginationModelChange={handlePaginationModelChange}
      rowCount={metaData.total}
      pageSizeOptions={[5, 10, 20, 50, 100]}
      disableSelectionOnClick
      checkboxSelection={checkboxSelection}
      onRowClick={(e, details) => (handleRowClick ? handleRowClick(e) : null)}
      getRowClassName={(params) =>
        params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
      }
      filterMode="server"
      paginationMode="server"
      sortingMode="server"
      onPageSizeChange={handlePageSizeChange}
      onSortModelChange={handleSorting}
      {...props}
    />
  );
};

export default DataTable;
