import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Box from "@mui/material/Box";

import SettingForm from "./PlanRateForm";
import Title from "../../common/Title";

import useAxios from "../../../hooks/useAxios";
import useAlert from "../../../hooks/useAlert";
import {
  __getPlanRateById,
  __updatePlanRate,
} from "../../../api/customer_plan_rate";

const PlanRateUpdate = () => {
  const nav = useNavigate();
  const [initialData, setInitialData] = useState();
  const { setAlertMessage } = useAlert();
  const { id } = useParams();
  const [response, call, apiLoading] = useAxios({
    ...__getPlanRateById(id),
  });
  const [updateResponse, updateCall, updateApiLoading] = useAxios(
    __updatePlanRate(id)
  );

  useEffect(() => {
    call();
  }, []);

  const handleFormSubmit = (data) => {
    updateCall({ data: data });
  };

  useEffect(() => {
    if (response && response.status) {
      setInitialData(response.data);
    }

    if (response && !response.status) {
      nav("/");
    }
  }, [response]);

  useEffect(() => {
    if (!updateResponse) return;
    if (updateResponse.status) {
      setAlertMessage({ type: "success", message: `Updated successfully.` });
    }
    if (updateResponse.data){
      setInitialData(updateResponse.data);
    }

  }, [updateResponse]);

  if (apiLoading || !initialData || updateApiLoading) {
    return <Title content="Please wait..." variant="subtitle2" gutterBottom />;
  }

  return (
    <Box
      sx={{ width: "100%" }}
      display="flex"
      alignItems="center"
      flexDirection="column"
    >
      <SettingForm
        isUpdate={true}
        initialValues={initialData}
        handleSubmit={handleFormSubmit}
        loading={apiLoading || updateApiLoading}
      />
    </Box>
  );
};

export default PlanRateUpdate;
