import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { Box } from "@mui/system";
import {
  Chip,
  Grid,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import Title from "../../common/Title";
import PaymentUpdateModal from "./PaymentUpdateModal";

import useAlert from "../../../hooks/useAlert";
import useAxios from "../../../hooks/useAxios";
import { url } from "../../../config/environment";
import { __getCustomerInfo, __updatePaymentInfo } from "../../../api/customer";
import {
  CUSTOMER_DETAILS,
  CUSTOMER_DETAIL_AMOUNTS,
  CUSTOMER_DETAIL_LABELS,
} from "../../../constants/value";

const CustomerInfo = () => {
  const { setAlertMessage } = useAlert();

  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const [response, call, loading] = useAxios({ ...__getCustomerInfo });
  const [paymentResponse, paymentCall, paymentLoading] = useAxios({
    ...__updatePaymentInfo,
  });

  useEffect(() => {
    call();
  }, []);

  useEffect(() => {
    if (response && response.status) {
      setData(response.data);
    }
  }, [response]);

  useEffect(() => {
    if (paymentResponse && paymentResponse.status) {
      setAlertMessage({ type: "success", message: `Updated Payment Info` });
      call();
      setOpenModal(false);
    }
  }, [paymentResponse]);

  if (!data || loading) {
    return <Title content="Please wait..." variant="subtitle2" gutterBottom />;
  }

  return (
    <Box sx={{ width: "75%" }}>
      <Title variant="h5" content={`Your affiliation status`} gutterBottom />
      {openModal && (
        <PaymentUpdateModal
          handleClose={setOpenModal}
          open={openModal}
          handleSubmit={(payload) => paymentCall(payload)}
          loading={loading || paymentLoading}
          initialValue={{
            preferred_payment: data.preferred_payment,
            payment_email: data?.payment_info?.includes("@")
              ? data.payment_info
              : "",
            payment_phone: data?.payment_info?.includes("@")
              ? ""
              : data.payment_info,
          }}
        />
      )}

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} stickyHeader>
          <TableHead>
            <TableRow key={data.referral_code}>
              <TableCell>Referral Code</TableCell>
              <TableCell>
                <Link
                  to={`${url.appUrl}?referral_code=${data.referral_code}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >{`${url.appUrl}?referral_code=${data.referral_code}`}</Link>
              </TableCell>
            </TableRow>
            <TableRow key={`data.referral_code`}>
              <TableCell>Affiliate Sign Up Referral</TableCell>
              <TableCell>
                <Link
                  to={`${url.currentAppUrl}/signUp?referral_code=${data.referral_code}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >{`${url.currentAppUrl}/signUp?referral_code=${data.referral_code}`}</Link>
              </TableCell>
            </TableRow>
            {Object.keys(CUSTOMER_DETAILS).map((key) => (
              <TableRow key={key}>
                <TableCell>{CUSTOMER_DETAILS[key]}</TableCell>
                <TableCell>
                  <Grid container>
                    <Grid item xs={6}>
                      {data[key] || "-"}
                    </Grid>
                    <Grid item>
                      <Chip
                        sx={{ ml: 2 }}
                        color="secondary"
                        onClick={(e) => setOpenModal(true)}
                        label="Update payment info"
                      />
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
            {Object.keys(CUSTOMER_DETAIL_LABELS).map((key) => (
              <TableRow key={key}>
                <TableCell>{CUSTOMER_DETAIL_LABELS[key]}</TableCell>
                <TableCell>{data[key] || "0"}</TableCell>
              </TableRow>
            ))}
            {Object.keys(CUSTOMER_DETAIL_AMOUNTS).map((key) => (
              <TableRow key={key}>
                <TableCell>{CUSTOMER_DETAIL_AMOUNTS[key]}</TableCell>
                <TableCell>{`$${
                  (data[key] && data[key].toFixed(2)) || "0.0"
                }`}</TableCell>
              </TableRow>
            ))}
          </TableHead>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CustomerInfo;
