import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

import {
  Box,
  Table,
  Paper,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TableContainer,
  Grid,
  Stack,
  Button,
} from "@mui/material";

import Title from "../../common/Title";
import ClaimApproveModal from "./ClaimModal";
import BalanceList from "../balance/BalanceList";

import useAxios from "../../../hooks/useAxios";
import useAlert from "../../../hooks/useAlert";
import useAuth from "../../../hooks/useAuth";
import { USER_TYPE } from "../../../constants/value";
import { __getBalances } from "../../../api/balance";
import { __getClaimById, __approveClaim } from "../../../api/claim";

const ClaimDetail = () => {
  const { claim_id } = useParams();
  const { user } = useAuth();
  const { setAlertMessage } = useAlert();

  const staff = user?.user_type === USER_TYPE.staff;

  const [claimData, setClaimData] = useState();
  const [balances, setBalances] = useState();
  const [openModal, setOpenModal] = useState(false);

  const [response, call, loading] = useAxios(__getClaimById(claim_id));
  const [balanceResponse, balanceCall, balanceLoading] = useAxios({
    ...__getBalances,
  });
  const [approveResponse, approveCall, approveLoading] = useAxios({
    ...__approveClaim(claim_id),
  });

  useEffect(() => {
    call();
    balanceCall({ params: { claim_id } });
  }, []);

  useEffect(() => {
    if (response && response.status) {
      setClaimData(response.data);
    }
  }, [response]);

  useEffect(() => {
    if (balanceResponse && balanceResponse.status) {
      setBalances(balanceResponse.data.data);
    }
  }, [balanceResponse]);

  useEffect(() => {
    if (approveResponse && approveResponse.status) {
      setAlertMessage({ type: "success", message: `Claim Approved` });
      call();
      balanceCall({ params: { claim_id } });
      setOpenModal(false);
    }
  }, [approveResponse]);

  if (
    loading ||
    balanceLoading ||
    !claimData
  ) {
    return <Title content="Please wait..." variant="subtitle2" gutterBottom />;
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Title variant="h5" content={`Claim Details: ${claim_id}`} gutterBottom />
      {staff && !claimData.approved_by && (
        <>
          <Grid container justifyContent="flex-start" spacing={2}>
            <Grid item sx={{ mb: "10px" }}>
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={loading || balanceLoading || approveLoading}
                  onClick={() => setOpenModal(true)}
                >
                  Approve Claim
                </Button>
              </Stack>
            </Grid>
          </Grid>
          {openModal && (
            <ClaimApproveModal
              handleClose={setOpenModal}
              open={openModal}
              handleSubmit={(data) => approveCall(data)}
              loading={approveLoading}
              claimData={claimData}
            />
          )}
        </>
      )}
      <Box
        sx={{ width: "100%", mb: 5 }}
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="left">ID</TableCell>
                <TableCell align="left">Claim Amount</TableCell>
                {staff && (
                  <TableCell align="left">Claim Customer Name</TableCell>
                )}
                <TableCell align="left">Claim Date</TableCell>
                <TableCell align="left">Approved Amount</TableCell>
                <TableCell align="left">Approved Date</TableCell>
                {staff && <TableCell align="left">Approving Staff</TableCell>}
                <TableCell align="left">Reference Num</TableCell>
                <TableCell align="left">Note</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left">{claimData.id}</TableCell>
                <TableCell align="left">{claimData.claim_amount}</TableCell>

                {staff && (
                  <TableCell align="left">
                    {claimData?.claiming_customer.name}
                  </TableCell>
                )}
                <TableCell align="left">
                  {claimData.claim_date &&
                    `${claimData?.claim_date?.toLocaleString()}`}
                </TableCell>
                <TableCell align="left">{claimData?.approved_amount}</TableCell>
                <TableCell align="left">
                  {claimData.approved_date &&
                    `${claimData?.approved_date?.toLocaleString()}`}
                </TableCell>
                {staff && (
                  <TableCell align="left">
                    {claimData?.approved_staff?.name}
                  </TableCell>
                )}
                <TableCell align="left">{claimData?.reference_num}</TableCell>
                <TableCell align="left">{claimData?.note}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <BalanceList
        loading={loading}
        data={balances}
        client={true}
        metaData={{ page: 1, page_size: 100 }}
      />
    </Box>
  );
};

export default ClaimDetail;
