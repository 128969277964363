const __getPlanRates = {
  method: "GET",
  url: "customer_plan_rate/",
};

const __getPlanRateById = (id) => ({
  method: "GET",
  url: `customer_plan_rate/${id}`,
});

const __updatePlanRate = (id) => ({
  method: "PUT",
  url: `customer_plan_rate/${id}`,
});

const __generateMissingPlanRate = (customer_id) => ({
  method: "POST",
  url: `customer_plan_rate/generate_missing/${customer_id}`,
});

export {
  __getPlanRates,
  __generateMissingPlanRate,
  __getPlanRateById,
  __updatePlanRate,
};
