import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { Box, InputLabel, OutlinedInput } from "@mui/material";

import Title from "../../common/Title";

const SettingForm = ({ isUpdate, handleSubmit, initialValues, loading }) => {
  const nav = useNavigate();

  const [formData, setFormData] = useState(null);

  const createSchema = yup.object({});
  const updateSchema = yup.object({
    affiliate_discount: yup.number("affiliate_discount"),
    sub_affiliate_discount: yup.number("sub_affiliate_discount"),
    total_billing_cycle: yup.number("total_billing_cycle"),
  });

  const validationSchema = isUpdate ? updateSchema : createSchema;

  const formik = useFormik({
    initialValues:
      initialValues && Object.keys(initialValues).length
        ? { ...initialValues }
        : {
            affiliate_discount: null,
            sub_affiliate_discount: null,
            total_billing_cycle: null,
          },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const requestData = Object.keys(values).reduce((obj, key) => {
        if (
          Object.keys(validationSchema.fields).includes(key) &&
          values[key] !== null
        ) {
          obj[key] = values[key];
        }
        return obj;
      }, {});

      setFormData(requestData);
    },
    validateOnChange: true,
  });

  useEffect(() => {
    if (formData) {
      handleSubmit(formData);
      if (!loading) {
        formik.setSubmitting(false);
      }
    }
  }, [formData]);

  if (loading) {
    return <Title content="Please wait..." variant="subtitle2" gutterBottom />;
  }

  return (
    <>
      <Box
        sx={{ width: "100%", mb: 5 }}
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Title
          variant="h5"
          content={`${isUpdate ? "Update" : "Create"} Setting`}
          sx={{ mb: 3 }}
          gutterBottom
        />
        <Grid
          sx={{ flexGrow: 1 }}
          container
          spacing={2}
          direction="column"
          justifyContent="center"
          alignItems="center"
          maxWidth={350}
        >
          <Grid item xs={12} style={{ width: "100%" }}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel htmlFor="id">Id</InputLabel>
              <OutlinedInput
                name="id"
                label="Id"
                id="id"
                type="number"
                value={initialValues?.id}
                disabled={true}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} style={{ width: "100%" }}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel htmlFor="plan_id">Plan Id</InputLabel>
              <OutlinedInput
                name="plan_id"
                label="Plan Id"
                id="plan_id"
                type="number"
                value={initialValues?.plan_id}
                disabled={true}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} style={{ width: "100%" }}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel htmlFor="plan_name">Plan Name</InputLabel>
              <OutlinedInput
                name="plan_name"
                label="Plan Name"
                id="plan_name"
                value={initialValues?.plan?.name}
                disabled={true}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} style={{ width: "100%" }}>
            <FormControl
              error={
                formik.touched.affiliate_discount &&
                Boolean(formik.errors.affiliate_discount)
                  ? true
                  : false
              }
              sx={{ width: "100%" }}
            >
              <InputLabel htmlFor="affiliate_discount">
                Affiliate Discount
              </InputLabel>
              <OutlinedInput
                name="affiliate_discount"
                label="Affiliate Discount"
                id="affiliate_discount"
                type="number"
                value={formik.values.affiliate_discount}
                onChange={formik.handleChange}
              />
              {formik.touched.affiliate_discount &&
              Boolean(formik.errors.affiliate_discount) ? (
                <FormHelperText id="affiliate_discount-error-text">
                  {formik.errors.affiliate_discount}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} style={{ width: "100%" }}>
            <FormControl
              error={
                formik.touched.sub_affiliate_discount &&
                Boolean(formik.errors.sub_affiliate_discount)
                  ? true
                  : false
              }
              sx={{ width: "100%" }}
            >
              <InputLabel htmlFor="sub_affiliate_discount">
                Sub-Affiliate Discount
              </InputLabel>
              <OutlinedInput
                name="sub_affiliate_discount"
                label="Sub-Affiliate Discount"
                id="sub_affiliate_discount"
                value={formik.values.sub_affiliate_discount}
                onChange={formik.handleChange}
              />
              {formik.touched.sub_affiliate_discount &&
              Boolean(formik.errors.sub_affiliate_discount) ? (
                <FormHelperText id="sub_affiliate_discount-error-text">
                  {formik.errors.sub_affiliate_discount}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} style={{ width: "100%" }}>
            <FormControl
              error={
                formik.touched.total_billing_cycle &&
                Boolean(formik.errors.total_billing_cycle)
                  ? true
                  : false
              }
              sx={{ width: "100%" }}
            >
              <InputLabel htmlFor="total_billing_cycle">
                Total Billing Cycle
              </InputLabel>
              <OutlinedInput
                name="total_billing_cycle"
                label="Total Billing Cycle"
                id="total_billing_cycle"
                value={formik.values.total_billing_cycle}
                onChange={formik.handleChange}
              />
              {formik.touched.total_billing_cycle &&
              Boolean(formik.errors.total_billing_cycle) ? (
                <FormHelperText id="total_billing_cycle-error-text">
                  {formik.errors.total_billing_cycle}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>

          <Grid
            container
            sx={{ mt: 3 }}
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <LoadingButton
                style={{ marginRight: "5px" }}
                disabled={Boolean(Object.keys(formik.errors).length)}
                onClick={() =>
                  !Object.keys(formik.errors).length &&
                  !loading &&
                  !formik.isSubmitting &&
                  formik.submitForm()
                }
                size="large"
                loading={loading}
                variant="contained"
              >
                Update
              </LoadingButton>
            </Grid>
            <Grid item>
              <LoadingButton
                style={{ marginRight: "5px" }}
                size="large"
                onClick={() => nav("/plan_rates/setting")}
                loading={loading}
                variant="contained"
              >
                Cancel
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SettingForm;
