import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Box } from "@mui/system";

import Title from "../../common/Title";
import DataTable from "../../common/DataTable";
import useAxios from "../../../hooks/useAxios";
import { Button, Grid, Stack } from "@mui/material";

import {
  __getPlanRates,
  __generateMissingPlanRate,
} from "../../../api/customer_plan_rate";
import useAlert from "../../../hooks/useAlert";
import { __addMissingSubscription } from "../../../api/balance";

const PlanRate = () => {
  const columns = [
    { field: "id", headerName: "ID", flex: 1 },
    {
      field: "plan_id",
      headerName: "Plan ID",
      flex: 1,
    },
    {
      field: "plan.name",
      headerName: "Plan",
      flex: 2,
      valueGetter: (params) => params.row?.plan?.name,
    },
    {
      field: "affiliate_discount",
      headerName: "Affiliate Discount",
      flex: 1,
    },
    {
      field: "sub_affiliate_discount",
      headerName: "Sub-Affiliate Discount",
      flex: 1,
    },
    {
      field: "total_billing_cycle",
      headerName: "Total Billing Cycle",
      flex: 1,
    },
  ];

  const nav = useNavigate();
  const { customer_id } = useParams();
  const { setAlertMessage } = useAlert();
  const [data, setData] = useState([]);

  const [response, call, loading] = useAxios({ ...__getPlanRates });
  const [regenerateResponse, regenerateCall, _] = useAxios({
    ...__generateMissingPlanRate(customer_id),
  });
  const [missingSubResponse, missingSubCall, missingSubLoading] = useAxios({
    ...__addMissingSubscription(customer_id),
  });

  useEffect(() => {
    if (!parseInt(customer_id)) {
      nav("/plan_rates/customer");
    }
  }, []);

  useEffect(() => {
    call({ params: { page: 1, page_size: 1000, customer_id } });
  }, []);

  useEffect(() => {
    if (response && response.status) {
      setData(response.data.data);
    }
  }, [response]);

  useEffect(() => {
    if (regenerateResponse && regenerateResponse.status) {
      setAlertMessage({
        type: "success",
        message: `Generated missing plan rates for customer id: ${customer_id}.`,
      });
      call({ params: { page: 1, page_size: 1000, customer_id } });
    }
  }, [regenerateResponse]);

  useEffect(() => {
    if (missingSubResponse && missingSubResponse.status) {
      setAlertMessage({
        type: "success",
        message: `Added missing active subscription referred by the customer id: ${customer_id}`,
      });
    }
  }, [missingSubResponse]);

  return (
    <Box sx={{ width: "100%" }}>
      <Title
        variant="h5"
        content={`Plan Rates for Customer: ${customer_id} ${
          data && data.length && data[0]?.customer?.name
        }`}
        gutterBottom
      />
      <Grid container justifyContent="flex-start" spacing={2}>
        <Grid item sx={{ mb: "10px" }}>
          <Stack spacing={2} direction="row">
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={() => regenerateCall()}
            >
              Generate Missing Plans
            </Button>
          </Stack>
        </Grid>
        <Grid item sx={{ mb: "10px" }}>
          <Stack spacing={2} direction="row">
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={() => missingSubCall()}
            >
              Add missing referred subscriptions
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <DataTable
        disableColumnFilter
        loading={loading || missingSubLoading}
        data={data}
        columns={columns}
        filterMode={"client"}
        client={true}
        metaData={{ page: 1, page_size: 100 }}
        handleRowClick={(params) => nav(`${params.id}`)}
      />
    </Box>
  );
};

export default PlanRate;
