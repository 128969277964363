import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box } from "@mui/system";
import { Button, Stack } from "@mui/material";

import Title from "../../common/Title";
import DataTable from "../../common/DataTable";
import useAxios from "../../../hooks/useAxios";

import useAuth from "../../../hooks/useAuth";
import useCompany from "../../../hooks/useCompany";
import { USER_TYPE } from "../../../constants/value";
import { __getCompanies } from "../../../api/customer";

const Companies = () => {
  const { setCompanyId } = useCompany();
  const nav = useNavigate();
  const { user } = useAuth();

  if (user.user_type !== USER_TYPE.staff || user.company_id !== 0) {
    nav("/balances");
  }
  const columns = [
    {
      field: "id",
      headerName: "Company ID",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 2,
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 250,
      flex: 2,
      renderCell: (params) => (
        <Stack spacing={1} direction="row">
          <Button
            variant="contained"
            onClick={(e) => {
              e.stopPropagation();
              setCompanyId({ companyId: params.row.id });
              nav("/balances");
            }}
          >
            Select Company
          </Button>
        </Stack>
      ),
    },
  ];

  const [data, setData] = useState([]);

  const [response, call, loading] = useAxios({ ...__getCompanies });

  useEffect(() => {
    call();
  }, []);

  useEffect(() => {
    if (response && response.status) {
      setData(response.data);
    }
  }, [response]);

  return (
    <Box sx={{ width: "100%" }}>
      <Title variant="h5" content={`Companies`} gutterBottom />
      <DataTable
        disableColumnFilter
        loading={loading}
        data={data}
        columns={columns}
        filterMode={"client"}
        client={true}
        metaData={{ page: 1, page_size: 100 }}
      />
    </Box>
  );
};

export default Companies;
